.player-scoring,
.player-favorites {
  cursor: pointer;
}

.player-scoring-td {
  overflow: auto;
  padding: 0 !important;
}

.scoring-table-header {
  color: var(--bs-white);
}

.scoring-table {
  cursor: default;
  vertical-align: baseline;
  margin-bottom: 0;
}

.rounds-scoring {
  font-weight: 600;
}

.scoring-table th {
  padding: 5px;
  font-weight: bold;
}

.scoring-table-body {
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.scoring-table-body > tr {
  &:nth-child(odd) {
    background-color: var(--bs-white);
  }
}

.table-head-scroing {
  color: #fff;
  font-weight: 400;
  padding: 5px;
  font-size: 13px;
  // text-transform: uppercase;
  border-style: hidden;
  text-align: center;
  height: 25px;
}

.table-head-scroing td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: var(--main-color-bg);
}

.scoring-table-body tr td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  @media (max-width: 600px) {
    background: #fff;
  }
}

.scoring-table-footer {
  border-top: 1px solid #000 !important;
  font-size: 13px;
  background-color: #fff;

  .legend-table {
    margin: 5px;
    gap: 5px;
    @media (min-width: 768px) {
      gap: 20px;
      justify-content: end;
    }
  }

  .legend-element {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .legend-icon {
    width: 15px;
    height: 15px;
    // border: 2px solid #fff;
  }

  .icon-circle {
    border-radius: 50%;
  }
}

.leaderboard-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: #fff;
  margin: auto;
}

.icon-type-circle {
  border-radius: 50%;
}

.icon-eagle {
  background: #f2ba47;
}

.icon-birdie {
  background: #c7332e;
}

.icon-bogey {
  background: #050015;
}

.icon-double-bogey {
  background: #2f63c4;
}

// .active-player-scoring {
// background-color: #cbcbcb !important;
// color: #fff !important;
// }
