.icon-star {
  cursor: pointer;
  svg {
    pointer-events: none;
    fill: var(--bs-gray-400);
    width: 20px;
    height: 20px;
    @media (max-width: 468px) {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    svg {
      fill: var(--bs-gray-600);
    }
  }
}

.active-row {
  .icon-star svg {
    fill: var(--bs-yellow);
  }
}

.table-style {
  table-layout: fixed;
  border-color: #eaeaea;

  font-size: 80%;
  @media (min-width: 576px) {
    font-size: 100%;
  }

  thead {
    tr {
      height: 49px;
    }
  }

  thead {
    text-transform: uppercase;
    font-size: 80%;
    background-color: var(--main-color-bg);
    // background-color: #5094c7;
    th {
      color: var(--bs-white);
    }
  }

  // tr > td:nth-of-type(1) {
  //   background: red;
  // }

  tbody {
    svg {
      transition: fill 200ms ease-in;
    }
    tr:hover {
      background-color: rgba(232, 232, 232, 0.2);
    }
  }
}

.title-row {
  background-color: var(--bs-gray-300);
  font-size: 80%;
}

.table-lg > :not(caption) > * > * {
  padding: 0.5rem 1rem;

  @media (max-width: 700px) {
    padding: 0.3rem 0.3rem;
  }
}

.auto-fullname {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 24px !important;
}

// [data-player-playing="true"]:after {
//   content: "";
//   background-image: url("data:image/svg+xml,%3Csvg id='icon-flights' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 542.59 542.59'%3E%3Cpath d='M253.851 124.664c0-22.109-18.178-40.039-40.631-40.039-22.424 0-40.603 17.92-40.603 40.039 0 22.232 18.178 40.22 40.603 40.22 22.414-.001 40.631-17.988 40.631-40.22z' /%3E%3Cpath d='M419.321 163.87c-1.588-8.587-8.635-13.521-9.056-13.895L187.554 3.191c-8.816-5.805-20.827-3.471-26.833 5.221l-11.274 16.352c-4.198 6.407-2.362 14.736 3.959 18.886 6.359 4.208 13.33 1.042 17.423-5.374l8.597-21.181 199.608 131.972-158.767 28.668c-11.284 1.817-19.393 13.101-16.878 24.078l34.54 133.655-111.7 167.593c-6.742 11.437-2.668 26.182 8.903 32.704 11.695 6.646 26.431 2.562 33.163-8.865 0 0 116.567-175.271 117.007-176.466l6.072 24.489-17.604 139.823c-2.171 13.015 6.761 25.302 20.005 27.502 13.205 2.218 25.675-6.627 27.922-19.642l18.055-143.505c.573-3.461-.144-11.073-.976-14.344l-39.79-158.422 103.667-18.666c11.113-1.959 18.58-12.63 16.668-23.799z' /%3E%3C/svg%3E");
//   position: absolute;
//   right: -15px;
//   bottom: 0;
//   top: 0;
//   margin: auto;
//   width: 15px;
//   height: 15px;
// }

.icon-legend {
  position: absolute;
  display: flex;
  right: -16px;
}

.player-name-header {
  padding-right: 10px;
}

.player-icon-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 5px;
  margin-top: -2px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.player-icon {
  // position: absolute;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // right: 0px;
  margin-left: 5px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.player-name-info {
  padding-left: 5px !important;
  .player-icon-header {
    margin-left: -2px;
  }
}

.leaderboard-content {
  .icon-legend {
    right: 0;
  }

  font-size: 90%;

  [data-id-flightnumber] {
    cursor: pointer;
  }

  .top-five-fullname {
    position: relative;
    display: flex;
    align-items: center;
  }
}

.auto-head,
.top-five-head {
  font-size: 72% !important;
}

.top-five-head {
  position: sticky;
  top: 0;
  z-index: 1;
  text-transform: uppercase;
  align-items: center;
  color: var(--bs-white);
  background-color: var(--main-color-bg);

  .top-five-par72 {
    line-height: 110%;
  }
}

.row-item:hover {
  background: #ededed !important;
}

.auto-selected {
  background: #ededed !important;
}

.table-players tbody tr:nth-child(even),
.table-favorites tbody tr:nth-child(even) {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.round-column {
  @media (max-width: 568px) {
    display: none;
  }
}

.round-select {
  max-width: 120px;
}

[data-fav="true"],
[data-fav-autocomplete="true"] {
  background-color: #fff7d5 !important;
  border-bottom: 1px solid #ffe677;

  &:hover {
    background-color: #ffed9b !important;
  }
}

.small-text {
  font-size: 12px;
}

.table-favorites th,
.table-players th {
  font-weight: normal;
}

.table-players tbody tr,
.table-favorites tbody tr {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    height: 100%;
    border-left: 5px solid var(--course-color);
  }
}

.layer-courses {
  gap: 2px 10px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin-bottom: -30px;
  }

  .button-layer {
    border-left: 28px solid var(--color-button-layer);
    padding: 2px 10px;
    white-space: nowrap;
  }
}
